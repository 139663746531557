.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-image-1{
  background-image: url(../public/img/bg_img_1.jpeg);
}

.infoPage{
  display: flex;
}

.mobileForm{
  display: none;
}

@media screen and (max-width: 991px) {
  .mainFormBody{
    display: none;
  }
  .infoPage{
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 991px) {
  .mobileForm{
    display:flex !important;
  }
}

.mainFormBody{
  width: 45%;
  height: 94vh;
}

.submitBtn{
  margin-top: 5%;
  width: 100%;
  height: 40px;
  border-radius: 5px;
}
.submitdiv{
  text-align: center;
}

.imageSize{
  width: 70%;
}

.error_msg {
  border-left: 5px solid red;
  border-radius: 2px;
  background-color: #ffe3e3;
  padding: 0 10px;
  margin-top: 3%;
}

.nrichTable tr td{
  padding-left: 30px;
  padding-top: 20px;
}

.react-tel-input .form-control{
  width: 100% !important;
}